<template>
  <div>
    <router-view></router-view>
    <v-row class="m-4">
      <v-col cols="12" md="12" class="text-center">
        <h4>
          Actualmente estás desconectado. Tus respuestas se guardarán cuando vuelvas a estar en línea. no cambies de página, cada 10 segundos intentaremos reconectar automáticamente...
        </h4>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Offline"
};
</script>

<style></style>
